body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.app {
  background: #1a1537;
}
.link {
  color: #2fd69d;
}

.slick-track {
  margin-left: inherit !important;
  margin-right: inherit !important;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
